import {useState} from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { basicFieldOptions, businessFieldOptions } from './RegistrationFieldOptions';
import EditableInput from "../../../../utils/EditableInput";
import ColorPallete from "../../../../utils/ColorPallete";
import RichText from "../../../../utils/RichText";
import RegistrationChoiceElementOptions from "./RegistrationChoiceElementOptions";
import RegistrationSsnElementOptions from './RegistrationSsnElementOptions';
import RegistrationPhoneElementOptions from "./RegistrationPhoneElementOptions";

const parseAgeRestriction = (value) => {
	if(typeof value === 'string'){
		value = value.trim();
		if(value === '') return null;
		if(/^-?[0-9]+$/.test(value)){
			return parseInt(value, 10)||null;
		}
	}
	if(typeof value === 'number'){
		if(Number.isInteger(value)) return value;
	}
	return null;
};
const isValidAgeRestriction = (value) => {
	if(typeof value === 'string') {
		value = value.trim();
		if(value === '') return true;
		return /^-?[0-9]+$/.test(value);
	}
	if(typeof value === 'number') return Number.isInteger(value);
	return false;
}

function RegistrationElementFieldOptions({ field, updateField, deleteField, updateFieldOption, addFieldOption, deleteFieldOption }) {
	const DragHandle = SortableHandle(() => <span className="material-icons font--25 item pointer">drag_indicator</span>);
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const getFieldDisplayType = () => {
		const f = [...basicFieldOptions, ...businessFieldOptions].find(f => {
			return f.customId === field?.customId
		})
		if (!f) return 'Field';
		return f.displayLabel
	}
	return (
		<li style={{ width: '100%' }}>
			<div className="registration__element">
				<div className="flex flex--row">
					<DragHandle />
					<div className="registration__element-inp">
						<div className="registration__field-type"> {getFieldDisplayType()}</div>
						{field.type === 'checkbox' &&
							<div className="md__input">
								<div className="mtop--10">
									<label>Label</label>
									<RichText item="label" returnEmptyString={true} value={field.label} updateAction={updateField} extra={{ field_id: field.id }} isSmall={true} isInline={true} placeholder="I agree" />
									<br />
									<label>Subtext</label>
									<RichText item={'html'} returnEmptyString={true} value={field.html} updateAction={updateField} extra={{ field_id: field.id }} isSmall={true} />
								</div>
							</div>
						}
						{!['checkbox'].includes(field.type) && <EditableInput extra={{ field_id: field.id }} item="label" type="text" startValue={field.label} updateAction={updateField} placeholder={'Type here...'} />}
						{['text', 'ssn', 'tel', 'firstName', 'lastName', 'email'].includes(field.type) && 
							<div className="mtop--10">
								<label className="text--muted font--12">Placeholder</label>
								<EditableInput extra={{ field_id: field.id }} item="placeholder" type="text" startValue={field.placeholder} updateAction={updateField} placeholder={'Type here...'} />
							</div>
						}
						{field.type === 'date' &&
							<div className="mtop--10">
								<label className="text--muted font--12">Placeholder</label>
								<div className="flex flex--row" style={{'gap':'5px'}}>
									<EditableInput extra={{ field_id: field.id }} item="dayPlaceholder" type="text" startValue={field.dayPlaceholder} updateAction={(name, value, extra) => updateField(name, value||null, extra)} placeholder={'Day'} />
									<EditableInput extra={{ field_id: field.id }} item="monthPlaceholder" type="text" startValue={field.monthPlaceholder} updateAction={(name, value, extra) => updateField(name, value||null, extra)} placeholder={'Month'} />
									<EditableInput extra={{ field_id: field.id }} item="yearPlaceholder" type="text" startValue={field.yearPlaceholder} updateAction={(name, value, extra) => updateField(name, value||null, extra)} placeholder={'Year'} />
								</div>
							</div>
						}
						<div className="mtop--10 flex flex--row">
							<div onClick={() => updateField('required', true, { field_id: field.id })} className={field.required ? 'btn btn-warning-pastel btn-md' : 'btn btn-secondary btn-md'}>Required</div>
							<div onClick={() => updateField('required', false, { field_id: field.id })} className={!field.required ? 'btn btn-warning-pastel btn-md' : 'btn btn-secondary btn-md'}>Not Required</div>
						</div>
						{field.type === 'tel' &&
							<RegistrationPhoneElementOptions field={field} updateField={updateField} />
						}
						{field.type === 'email' &&
							<div className="mtop--10 btn btn-secondary btn-md flex flex--row">
								<EditableInput item="unique" type="checkbox" extra={{ field_id: field.id }} startValue={field?.unique} updateAction={updateField} placeholder={'Unique'} />
								<label className="mleft--10 mtop--5">Unique</label>
							</div>
						}
						{field.customId === 'marketingConsent' &&
							<div className="md__input">
								<div className="mtop--10 btn btn-secondary btn-md flex flex--row">
									<EditableInput item="doubleOptIn" type="checkbox" extra={{ field_id: field.id }} startValue={field?.doubleOptIn} updateAction={updateField} placeholder={'doubleOptIn'} />
									<label className="mleft--10 mtop--5">Double opt-in</label>
								</div>
							</div>
						}
						{field.type === 'choice' &&
							<RegistrationChoiceElementOptions
								field={field}
								updateField={updateField}
								updateFieldOption={updateFieldOption}
								addFieldOption={addFieldOption}
								deleteFieldOption={deleteFieldOption}
							/>
						}
						{field.type === 'ssn' &&
							<RegistrationSsnElementOptions
								field={field}
								updateField={updateField}
							/>
						}
						{field.customId === 'dob' &&
							<>
								{!parseAgeRestriction(field.ageRestriction) && <div onClick={() => updateField('ageRestriction', 18, { field_id: field.id })} className='btn btn-primary mtop--10 btn-md'>
									<span className="material-icons font--20 vm--align">add</span>
									Add age restriction
								</div>}
								{parseAgeRestriction(field.ageRestriction) && <>
									<div className="md__input">
										<div className="mtop--10">
											<label>Age Restriction</label>
											<EditableInput item="ageRestriction" type="number" startValue={field.ageRestriction} updateAction={(field_id, fieldValue)=>isValidAgeRestriction(fieldValue)&&updateField(field_id, parseAgeRestriction(fieldValue), { field_id: field.id })} placeholder={'Age restriction (Optional)...'} />
										</div>
									</div>
									{isValidAgeRestriction(field.ageRestriction) && parseAgeRestriction(field.ageRestriction) && <div className="md__input">
										<label className="text--muted font--12">Error message</label>
										<EditableInput extra={{ field_id: field.id }} item="ageRestrictionText" type="text" startValue={field.ageRestrictionText} updateAction={(field_id, fieldValue)=>updateField(field_id, fieldValue?fieldValue:null, { field_id: field.id })} placeholder={'Type here...'} />
										<div className="flex flex--row mtop--10" style={{gap:'10px'}}>
											<div className="ptop--5">
												<ColorPallete dropdown={true} item="ageRestrictionTextColor" value={field.ageRestrictionTextColor||'#D23369'} type={'hex'} updateAction={(field_id, fieldValue)=>updateField(field_id, fieldValue?fieldValue:null, { field_id: field.id })} />
											</div>
											<div>
												<label className="text--bold">Text color</label>
												<div className="text--muted font--12">The age restriction text color</div>
											</div>
										</div>
									</div>}
								</>||null}
								{parseAgeRestriction(field.ageRestriction) && <div onClick={() => updateField('ageRestriction', null, { field_id: field.id })} className='btn btn-primary mtop--10 btn-md'>
									<span className="material-icons font--20 vm--align">remove</span>
									Remove age restriction
								</div>||null}
							</>
						}
					</div>
					<span className="material-icons font--25 text--danger mtop--10 pointer" onClick={() => setDeleteConfirm(true)}>
						clear
					</span>
				</div>
				{ deleteConfirm &&
					<div className="registration__delete">
						Are you sure you want to delete this field?
						<div className="flex flex--row">
							<button className="btn btn-secondary" onClick={() => setDeleteConfirm(false)}>Cancel</button>
							<button className="btn btn-danger" onClick={() => deleteField(field.id)}>Delete</button>
						</div>
					</div>
				}
			</div>
		</li>
	)
}

export default RegistrationElementFieldOptions
