import { useState } from "react";
import ColorPallete from "../../../utils/ColorPallete";
import {useDispatch, useSelector} from 'react-redux';
import EditableInput from "../../../utils/EditableInput";
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";
import Dropdown from "../../../utils/Dropdown";

const availableFontSizes = ['11px', '12px', '13px', '14px', '15px', '16px', '17px', '18px', '19px', '20px', '22px', '24px', '26px'];
function SubmitElementOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const {background, text, marginTop, textAlign, textColor, width, fontSize} = elementCopy;
	const update = (key, value) => {
		if(key === 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	return (
		<div>
			<div className="campaign__option">
				<label className="text--bold">Text</label>
				<EditableInput item="text" type="text" startValue={text} updateAction={update} placeholder="Submit" />
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Text color</label>
					<div className="campaign__option-act">
						<ColorPallete item="textColor" dropdown value={textColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="campaign__option campaign__option-50 mleft--5">
					<label className="text--bold">Background color</label>
					<div className="campaign__option-act">
						<ColorPallete item="background" dropdown value={background} type={'rgba'} updateAction={update} />
					</div>
				</div>
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Align</label>
				<div className="flex flex--row campaign__option-act">
					<div className={textAlign === 'left' ? 'btn btn-primary' : 'btn btn-secondary'}  onClick={() => update('textAlign', 'left')}>Left</div>
					<div className={textAlign === 'center' ? 'btn btn-primary' : 'btn btn-secondary'}  onClick={() => update('textAlign', 'center')}>Center</div>
					<div className={textAlign === 'right' ? 'btn btn-primary' : 'btn btn-secondary'}  onClick={() => update('textAlign', 'right')}>Right</div>
				</div>
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Width</label>
					<div className="flex flex--row campaign__option-act">
						<div className={width === 'auto' ? 'btn btn-primary' : 'btn btn-secondary'}  onClick={() => update('width', 'auto')}>Auto</div>
						<div className={width === 'full' ? 'btn btn-primary' : 'btn btn-secondary'}  onClick={() => update('width', 'full')}>Full</div>
					</div>
				</div>
				<div className="campaign__option campaign__option-50 mleft--5">
					<label className="text--bold">Font Size</label>
					<div className="campaign__option-act">
						<Dropdown dropup={false}>
							<div className="dropdown-trigger btn btn-secondary btn-100">
								{fontSize ? fontSize : '14px'}
								<span className="material-icons font--20 vm--align">
									keyboard_arrow_down
								</span>
							</div>
							<div className="dropdown__menu">
								<div className="dropdown__content">
									{availableFontSizes.map((size) => {
										return (
											<div key={size} className="dropdown__item"  onClick={() => update('fontSize', size)}>
												{size}
											</div>
										)
									})}
								</div>
							</div>
						</Dropdown>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SubmitElementOptions;
